<template>
  <div class="flex min-h-screen w-full flex-col justify-between text-gray-800">
    <NuxtErrorBoundary>
      <div v-if="navigations && navigations.main">
        <NavigationMain
          :nav="navigations?.main"
          :meta="navigations?.mainmeta"
        />
      </div>
    </NuxtErrorBoundary>

    <main class="flex-1 py-[30vh]">
      <div class="prose w-full min-w-full text-center">
        <h1 class="mb-4 text-9xl">{{ error.statusCode }}</h1>
        <h2>{{ message || error.message }}</h2>
        <AppLinkWithIcon to="/" aria-label="Zurück zur Startseite"
          >Zurück zur Startseite</AppLinkWithIcon
        >
      </div>
    </main>
    <NuxtErrorBoundary>
      <div v-if="navigations && navigations.footer">
        <NavigationFooter
          :nav="navigations.footer"
          :globals="navigations.globals"
          :meta="navigations.footermeta"
        />
      </div>
    </NuxtErrorBoundary>
  </div>
</template>

<script setup>
import { store } from '/store.js'
const props = defineProps({
  error: Object,
  required: true,
})

// Fetch all navigations and globals
const { data: navigations } = await useAsyncGql({
  operation: 'Navigations',
})

// populate the store with globals
store.globals = navigations.value?.globals
// populate the store with the main nav for the breadcrumbs
store.navigations = { main: navigations?.value?.main }

let message = null
if (props.error.statusCode === '404') {
  message = 'Seite nicht gefunden.'
} else {
  message = 'Es ist ein Fehler aufgetreten.'
}
const handleError = () => clearError({ redirect: '/' })
</script>
